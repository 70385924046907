/* eslint-disable camelcase */
import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { CardLink, Grid, Layout, AutobotVideo } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @component
 * Article graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const Products = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // variables

  const products = !data?.allStoryblokEntry?.edges?.[0]
    ? []
    : data.allStoryblokEntry.edges.map(({ node }) => ({
      ...node,
      content: JSON.parse(node.content)
    }));

  // ---------------------------------------------------------------------------
  // render

  const seo = { seoDescription: ``, seoKeywords: `` };

  return (
    <Layout location={location} seo={seo}>
      <section>
        <Grid>
          <header
            css={css`
              grid-column: span 12 / span 12;
              position: relative;
              padding: 6rem 0 2rem;
              text-transform: uppercase;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              gap: 32px;

              @media (min-width: 976px) {
                flex-direction: row;
                align-items: center;
              }
            `}
          >
            <h1 className="h1">All Products</h1>
            <div css={css`
                display: flex;
                gap: 24px;
                max-width: 530px;
                align-items: center;
                flex-direction: column-reverse;

                @media (min-width: 550px) {
                  flex-direction: row;
                }
              `}>
              <p className="b1" css={css`
                  text-transform: none;
                  font-weight: 400;
                `}>
                For product pricing, opportunities to purchase, and to explore design possibilities for your space,&nbsp;
                <a css={css`
                text-decoration: underline;
                  `} href="https://www.dirtt.com/contact-us/">contact us
                </a>.
              </p>
              <AutobotVideo
                css={css`
                  width: 100%;
                  max-width: 320px;
                  position: relative;
                  display: block;

                  @media (min-width: 550px) {
                    width: 320px;
                  }
                `}
                autoPlay
                controls={false}
                muted
                loop
                playsInline
                src={'https://dam.dirtt.com/video/upload/v1726166130/logo-animated_s7bpls.mp4'}
              />
            </div>
          </header>
        </Grid>
      </section>

      <section
        css={css`
          width: 100%;
          position: relative;
          padding: 3rem 0;
        `}
      >
        {products?.[0] && (
          <Grid>
            {products.map((item) => {
              const itemData = {
                ...item,
                name: item?.content?.name,
                hoverTagline: item?.content.hover_tagline
              };

              if (item?.content?.colours?.[0]) {
                itemData.image = item.content.colours[0].image;
              }

              //

              return (
                <div
                  key={`material-${item?.full_slug}`}
                  css={css`
                    grid-column: span 12 / span 12;
                    margin-bottom: 2rem;
                    order: ${item?.content.SortOrder || 100};

                    ${breakpoint(`large-tablet`)} {
                      grid-column: span 4 / span 4;
                    }
                  `}
                >
                  <CardLink data={itemData} objectFit={'contain'} />
                </div>
              );
            })}
          </Grid>
        )}
      </section>
    </Layout>
  );
};

export default Products;

export const query = graphql`
  query Products {
    allStoryblokEntry(filter: { full_slug: { regex: "/products/(.*)/" } }) {
      edges {
        node {
          id
          uuid

          name
          path
          position
          slug
          lang
          group_id
          full_slug
          content
        }
      }
    }
  }
`;
